/* eslint-disable no-promise-executor-return */
import { apiTools } from '../api';

import {
  fetchDataInit, fetchDataStart, fetchDataSuccess, fetchDataError,
} from '../actions/api.action';

import {
  GET_TEST_SI_USERS,
  storeTestSIUsers,
  GET_TEST_SI_RESULT,
  storeTestSIResult,
} from '../actions/testsi.action';

export default (store) => (next) => async (action) => {
  const { dispatch } = store;
  const { log } = console;
  switch (action.type) {
    case GET_TEST_SI_USERS: {
      const headers = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.accessToken}`,
        },
      };
      try {
        dispatch(fetchDataInit());
        dispatch(fetchDataStart());
        const { data } = await apiTools.get('/admin/users?toolId=2', headers);
        const { message, users } = data;
        dispatch(storeTestSIUsers(users));
        dispatch(fetchDataSuccess(message));
        if (process.env.NODE_ENV === 'development') log(data);
      } catch (error) {
        const { message, detail } = error.response.data.error;
        dispatch(fetchDataError(`${message} - ${detail}`));
        if (process.env.NODE_ENV === 'development') log(error.response.data.error);
      }
      return next(action);
    }
    case GET_TEST_SI_RESULT: {
      const { userId } = action;
      const headers = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.accessToken}`,
        },
      };
      try {
        dispatch(fetchDataInit());
        dispatch(fetchDataStart());
        const { data } = await apiTools.get(`/admin/result?toolId=2&userId=${userId}`, headers);
        const {
          message, date, firstname, result,
        } = data;
        dispatch(storeTestSIResult({
          date,
          firstname,
          result,
        }));
        dispatch(fetchDataSuccess(message));
        if (process.env.NODE_ENV === 'development') log(data);
      } catch (error) {
        const { message, detail } = error.response.data.error;
        dispatch(fetchDataError(`${message} - ${detail}`));
        if (process.env.NODE_ENV === 'development') log(error.response.data.error);
      }
      return next(action);
    }
    default:
      return next(action);
  }
};
