import { STORE_TEST_SI_USERS, STORE_TEST_SI_RESULT, CLEAR_TEST_SI_DATA } from '../actions/testsi.action';

export const initialState = {
  users: [],
  currentUserResult: {
    date: null,
    firstname: null,
    result: {},
  },
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case STORE_TEST_SI_USERS:
      return {
        ...state,
        users: action.users,
      };
    case STORE_TEST_SI_RESULT:
      return {
        ...state,
        currentUserResult: action.result,
      };
    case CLEAR_TEST_SI_DATA:
      return {
        ...state,
        users: [],
        currentUserResult: {
          date: null,
          firstname: null,
          result: {},
        },
      };
    default:
      return state;
  }
};

export default reducer;
