import { connect } from 'react-redux';
import TestSyndromeImposteurBoard from '../../views/Dashboard/Content/TestSyndromeImposteurBoard';

import { getTestSIUsers, getTestSIResult } from '../../actions/testsi.action';

const mapStateToProps = (state) => ({
  users: state.testsi.users,
  loading: state.api.loading,
  userResult: state.testsi.currentUserResult,
});

const mapDispatchToProps = (dispatch) => ({
  getTestSIUsers: () => dispatch(getTestSIUsers()),
  getTestSIResult: (userId) => dispatch(getTestSIResult(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestSyndromeImposteurBoard);
