import { connect } from 'react-redux';
import Router from '../../components/Router';

import { apiToolsWakeUp, apiTallyManagerWakeUp } from '../../actions/auth.action';

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => ({
  wakeupApiTools: () => dispatch(apiToolsWakeUp()),
  wakeupApiTallyManager: () => dispatch(apiTallyManagerWakeUp()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
