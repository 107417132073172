import PropTypes from 'prop-types';

import { ResponsiveBar } from '@nivo/bar';

import useBreakpoint from '../../../hooks/useBreakpoint';

import './styles.scss';

const ComponentsChart = ({ color, data }) => {
  const breakpoint = useBreakpoint();

  return (
    <div className="components-chart text-center">
      <ResponsiveBar
        data={data}
        keys={[
          'score',
        ]}
        indexBy="component"
        layout="horizontal"
        colors={color}
        labelTextColor="#FFF"
        padding={0.3}
        margin={{
          top: 30,
          right: 0,
          bottom: 30,
          left: breakpoint <= 768 ? 280 : 500,
        }}
        minValue={0}
        maxValue={1}
        valueFormat=">-.0%"
        enableGridY={false}
        axisBottom={false}
        axisLeft={{
          tickPadding: 5,
          tickRotation: 0,
          truncateTickAt: 0,
          tickSize: 0,
        }}
        labelSkipWidth={5}
        theme={{
          text: {
            fill: '#1D1D1B',
            fontSize: breakpoint <= 768 ? 12 : 14,
          },
        }}
      />
    </div>
  );
};

ComponentsChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    component: PropTypes.string,
    score: PropTypes.number,
    scoreColor: PropTypes.string,
    empty: PropTypes.number,
    emptyColor: PropTypes.string,
  })).isRequired,
  color: PropTypes.string.isRequired,
};

export default ComponentsChart;
