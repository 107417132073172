import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Container, Row, Col, Image, Card, Button,
} from 'react-bootstrap';

import { IconContext } from 'react-icons';
import { FaCheck } from 'react-icons/fa';
import { HiChevronDoubleRight } from 'react-icons/hi';
import {
  TbArrowBigDownLineFilled,
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
} from 'react-icons/tb';
import useBreakpoint from '../../hooks/useBreakpoint';

import vanessa from '../../assets/img/vanessa-couch.webp';

import TallyForm from '../../components/TallyForm';

import './styles.scss';

const WorkinDiag = ({ booking }) => {
  const breakpoint = useBreakpoint();

  const checkCSS = useMemo(() => ({
    size: breakpoint > 768 ? '2rem' : '1.5rem',
    className: 'me-1 me-md-2 ms-3 ms-md-5 text-wkc-violet',
  }), [breakpoint]);
  const chevronCSS = useMemo(() => ({
    size: '2.5rem',
    className: 'me-3 text-white',
  }), []);
  const arrowCSS = useMemo(() => ({
    size: '3.5rem',
    className: 'my-3 text-wkc-yellow bounce',
  }), []);
  const numberCSS = useMemo(() => ({
    size: '2.5rem',
    className: 'me-3 text-wkc-violet',
  }), []);

  const tallyLink = useMemo(() => (booking
    ? 'https://tally.so/embed/3jL8JY?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1'
    : 'https://tally.so/embed/w2vP9e?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1'), [booking]);

  const [showDiag, setShowDiag] = useState(false);

  return (
    <div className="workindiag">
      {
        showDiag ? (
          <section className="section-tally">
            <Container>
              <Row className="justify-content-center">
                <Col xs={12} md={8}>
                  <TallyForm link={tallyLink} />
                </Col>
              </Row>
            </Container>
          </section>
        ) : (
          <>
            <section className="section1">
              <Container fluid>
                <Row>
                  <Col
                    xs={{ span: 12, order: 2 }}
                    md={{ span: 4, order: 1 }}
                    className="px-0 section1__image"
                  >
                    <Image
                      className="vanessa"
                      src={vanessa}
                      alt="Vanessa"
                      fluid
                      loading="lazy"
                      decoding="async"
                    />
                  </Col>
                  <Col
                    xs={{ span: 12, order: 1 }}
                    md={{ span: 8, order: 2 }}
                    className="section1__title text-center bg-wkc-lightyellow d-flex flex-column align-items-center justify-content-center"
                  >
                    <h1 className="text-uppercase ff--bold mb-5">
                      Ton <span className="lighted--violet">Workin'Diag</span>
                    </h1>
                    <h2 className="text-uppercase">
                      Le full check-up<br />
                      pour entrepreneur·e
                    </h2>
                    <p className="mt-5 fs-4 ff--italic text-uppercase">
                      Réalise un diag'
                    </p>
                    <p className="toggles d-flex flex-row align-items-center justify-content-center">
                      <IconContext.Provider value={checkCSS}>
                        <FaCheck />
                      </IconContext.Provider>
                      <strong className="text-uppercase">
                        Clair
                      </strong>
                      <IconContext.Provider value={checkCSS}>
                        <FaCheck />
                      </IconContext.Provider>
                      <strong className="text-uppercase">
                        Précis
                      </strong>
                      <IconContext.Provider value={checkCSS}>
                        <FaCheck />
                      </IconContext.Provider>
                      <strong className="text-uppercase">
                        Ultra-complet
                      </strong>
                    </p>
                    <p className="fs-4">
                      de <span className="lighted--violet">ton business</span>&nbsp;
                      ET <span className="lighted--violet">ton bien-être</span>
                    </p>
                  </Col>
                </Row>
              </Container>
            </section>
            <section className="section2 py-5 bg-wkc-violet text-white">
              <Container>
                <Row className="justify-content-center">
                  <Col xs={12} md={10}>
                    <h3 className="text-uppercase ff--bold d-flex align-items-center">
                      <IconContext.Provider value={chevronCSS}>
                        <HiChevronDoubleRight />
                      </IconContext.Provider>
                      Pourquoi faire ce check-up ?
                    </h3>
                    <p className="fs-4">
                      Conçu par les coachs et psychologues Workin’Cool, ce diag' va te
                      permettre de mieux comprendre où
                      tu en es, ton <strong>mode de fontionnement</strong> d'entrepreneur·e et
                      identifier <strong>tes atouts</strong>, <strong>besoins </strong>
                      & <strong>tes enjeux</strong> du moment.
                    </p>
                  </Col>
                </Row>
              </Container>
            </section>
            <section className="section3 py-5">
              <Container>
                <Row className="justify-content-center">
                  <Col xs={12} md={6}>
                    <h2 className="text-uppercase fs-1 ff--bold text-center mb-3 text-wkc-violet">
                      En seulement 3 étapes
                      <br />
                      <IconContext.Provider value={arrowCSS}>
                        <TbArrowBigDownLineFilled />
                      </IconContext.Provider>
                    </h2>
                    <Card className="section3__step-card">
                      <Card.Body>
                        <ul className="p-0 m-0">
                          <li className="d-flex align-items-center fs-4 my-4">
                            <IconContext.Provider value={numberCSS}>
                              <TbSquareRoundedNumber1Filled />
                            </IconContext.Provider>
                            Réalise ton Workin'Diag <br className="d-md-none" />(10 - 15min).
                          </li>
                          <li className="d-flex align-items-center fs-4 my-4">
                            <IconContext.Provider value={numberCSS}>
                              <TbSquareRoundedNumber2Filled />
                            </IconContext.Provider>
                            Prends rendez-vous avec Vanessa.
                          </li>
                          <li className="d-flex align-items-center fs-4 my-4">
                            <IconContext.Provider value={numberCSS}>
                              <TbSquareRoundedNumber3Filled />
                            </IconContext.Provider>
                            Bénéficie d'une analyse complète de ton diag'.
                          </li>
                        </ul>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="justify-content-center my-5">
                  <Col xs={12} md={6} className="text-center">
                    <Button
                      variant="wkc-yellow"
                      onClick={() => setShowDiag(true)}
                    >
                      <span className="fs-4">Je fais mon Workin'Diag !</span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </section>
          </>
        )
      }
    </div>
  );
};

WorkinDiag.propTypes = {
  booking: PropTypes.bool.isRequired,
};

export default WorkinDiag;
