import { legacy_createStore as createStore, compose, applyMiddleware } from 'redux';

import rootReducer from '../reducers';

import authMiddleware from '../middlewares/auth.middleware';
import workinDiagMiddleware from '../middlewares/workindiag.middleware';
import reportMiddleware from '../middlewares/report.middleware';
import testSIMiddleware from '../middlewares/testsi.middleware';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancers = composeEnhancers(
  applyMiddleware(
    authMiddleware,
    workinDiagMiddleware,
    reportMiddleware,
    testSIMiddleware,
  ),
);

const store = createStore(
  rootReducer,
  enhancers,
);

export default store;
