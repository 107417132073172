import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Container, Row, Col, Form, Card, Button,
} from 'react-bootstrap';

import LoadingSpin from '../../../../components/LoadingSpin';

import './styles.scss';

const TestSyndromeImposteurBoard = ({
  users, loading, getTestSIUsers, getTestSIResult, userResult,
}) => {
  useEffect(() => {
    if (!loading && users.length === 0) {
      getTestSIUsers();
    }
  }, [users, loading]);

  const [optionSelected, setOptionSelected] = useState(false);
  const [showTestSI, setShowTestSI] = useState(false);

  const handleChangeOption = () => {
    setOptionSelected(true);
    setShowTestSI(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    getTestSIResult(event.target.user.value);
  };
  useEffect(() => {
    if (userResult.firstname) {
      setShowTestSI(true);
    }
  }, [userResult]);

  const handleBackward = () => {
    setOptionSelected(false);
    setShowTestSI(false);
  };

  return (
    <div className="wd-board">
      <Container>
        <Row className="justify-content-center pt-md-3">
          <Col xs={12} md={6} className="text-center">
            <h2>Test du Syndrome de l'Imposteur Dashboard</h2>
          </Col>
        </Row>
        {
          loading && <LoadingSpin color="violet" />
        }
        {
          !loading && !showTestSI && users.length > 0 && (
            <Row className="justify-content-center">
              <Col xs={12} md={4}>
                <Card className="wd-board__customer-select">
                  <Form onSubmit={handleSubmit} className="">
                    <Card.Body>
                      <Form.Group controlId="user">
                        <Card.Title>
                          <Form.Label>
                            Sélectionner un client
                          </Form.Label>
                        </Card.Title>
                        <Form.Select
                          onChange={handleChangeOption}
                        >
                          {
                            !optionSelected && <option value="no option">...</option>
                          }
                          {
                            users.map(({
                              id, firstname, lastname,
                            }) => (
                              <option key={id} value={id}>
                                {firstname} {lastname}
                              </option>
                            ))
                          }
                        </Form.Select>
                      </Form.Group>
                    </Card.Body>
                    <Card.Footer className="bg-white text-center py-3">
                      <Button
                        variant="wkc-violet"
                        type="submit"
                        disabled={!optionSelected}
                      >
                        <span>Voir le résulat</span>
                      </Button>
                    </Card.Footer>
                  </Form>
                </Card>
              </Col>
            </Row>
          )
        }
        {
          !loading && showTestSI && (
            <>
              <Row className="justify-content-center py-3">
                <Col xs={12} md={6} className="text-center">
                  <Card className="wd-board__chart-header">
                    <Card.Body>
                      <Card.Title as="h4" className="ff--bold">{userResult.firstname}</Card.Title>
                      <Card.Text className="">
                        <span className="text-decoration-underline">Test du Syndrome de l'Imposteur réalisé le :</span><br />
                        <strong>{new Date(userResult.date).toLocaleDateString()}</strong>
                      </Card.Text>
                      <Card.Text>
                        Score : <strong>{userResult.result.score}</strong>
                      </Card.Text>
                      <Card.Text>
                        Interprétation :<br />
                        <strong>{userResult.result.definition}</strong>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs={12} md={6} className="text-center">
                  <Button
                    variant="wkc-black-outlined"
                    onClick={handleBackward}
                  >
                    <span>Retour</span>
                  </Button>
                </Col>
              </Row>
            </>
          )
        }
      </Container>
    </div>
  );
};

TestSyndromeImposteurBoard.propTypes = {
  users: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  getTestSIUsers: PropTypes.func.isRequired,
  getTestSIResult: PropTypes.func.isRequired,
  userResult: PropTypes.object.isRequired,
};

export default TestSyndromeImposteurBoard;
