import { apiTools, apiTallyManager } from '../api';

import {
  fetchDataInit, fetchDataStart, fetchDataSuccess, fetchDataError,
} from '../actions/api.action';

import {
  API_TOOLS_WAKEUP, API_TALLY_MANAGER_WAKEUP, SUBMIT_LOGIN, login, REHYDRATE, LOGOUT,
} from '../actions/auth.action';

import { clearWorkinDiagData } from '../actions/workindiag.action';

import { saveStorage, removeStorage } from '../utils/localStorage.util';

export default (store) => (next) => async (action) => {
  const { dispatch } = store;
  const { log } = console;
  switch (action.type) {
    case API_TOOLS_WAKEUP: {
      try {
        const { data } = await apiTools.get('/wakeup');
        if (process.env.NODE_ENV === 'development') log(data.message);
      } catch (error) {
        if (process.env.NODE_ENV === 'development') log(error.response.data.error);
      }
      return next(action);
    }
    case API_TALLY_MANAGER_WAKEUP: {
      try {
        const { data } = await apiTallyManager.get('/');
        if (process.env.NODE_ENV === 'development') log(data.message);
      } catch (error) {
        if (process.env.NODE_ENV === 'development') log(error.response.data.error);
      }
      return next(action);
    }
    case SUBMIT_LOGIN: {
      const {
        email: submittedEmail,
        password: submittedPassword,
      } = action;
      const body = {
        email: submittedEmail,
        password: submittedPassword,
      };
      try {
        dispatch(fetchDataInit());
        dispatch(fetchDataStart());
        const { data } = await apiTools.post('/auth', body);
        const { message, accessToken } = data;
        dispatch(login(accessToken));
        dispatch(fetchDataSuccess(message));
        saveStorage('wkc-tools-token', accessToken);
        if (process.env.NODE_ENV === 'development') log(data);
      } catch (error) {
        const { status, message, detail } = error.response.data.error;
        dispatch(fetchDataError(`${status} - ${message} - ${detail}`));
        if (process.env.NODE_ENV === 'development') log(error.response.data.error);
      }
      return next(action);
    }
    case REHYDRATE: {
      const { accessToken: storedAccessToken } = action;
      const headers = {
        headers: {
          Authorization: `Bearer ${storedAccessToken}`,
        },
      };
      try {
        dispatch(fetchDataInit());
        dispatch(fetchDataStart());
        const { data } = await apiTools.get('/auth', headers);
        const { message, accessToken } = data;
        dispatch(login(accessToken));
        dispatch(fetchDataSuccess(message));
        saveStorage('wkc-tools-token', accessToken);
        if (process.env.NODE_ENV === 'development') log(data);
      } catch (error) {
        const { status, message, detail } = error.response.data.error;
        dispatch(fetchDataError(`${status} - ${message} - ${detail}`));
        if (process.env.NODE_ENV === 'development') log(error.response.data.error);
      }
      return next(action);
    }
    case LOGOUT: {
      dispatch(fetchDataInit());
      dispatch(clearWorkinDiagData());
      removeStorage('wkc-tools-token');
      return next(action);
    }
    default:
      return next(action);
  }
};
