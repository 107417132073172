export const API_TOOLS_WAKEUP = 'API_TOOLS_WAKEUP';

export const apiToolsWakeUp = () => ({
  type: API_TOOLS_WAKEUP,
});

export const API_TALLY_MANAGER_WAKEUP = 'API_TALLY_MANAGER_WAKEUP';

export const apiTallyManagerWakeUp = () => ({
  type: API_TALLY_MANAGER_WAKEUP,
});

export const REHYDRATE = 'REHYDRATE';

export const rehydrate = (accessToken) => ({
  type: REHYDRATE,
  accessToken,
});

export const SUBMIT_LOGIN = 'SUBMIT_LOGIN';

export const submitLogin = (email, password) => ({
  type: SUBMIT_LOGIN,
  email,
  password,
});

export const LOGIN = 'LOGIN';

export const login = (accessToken) => ({
  type: LOGIN,
  accessToken,
});

export const LOGOUT = 'LOGOUT';

export const logout = () => ({
  type: LOGOUT,
});
