export const GET_TEST_SI_USERS = 'GET_TEST_SI_USERS';

export const getTestSIUsers = () => ({
  type: GET_TEST_SI_USERS,
});

export const STORE_TEST_SI_USERS = 'STORE_TEST_SI_USERS';

export const storeTestSIUsers = (users) => ({
  type: STORE_TEST_SI_USERS,
  users,
});

export const GET_TEST_SI_RESULT = 'GET_TEST_SI_RESULT';

export const getTestSIResult = (userId) => ({
  type: GET_TEST_SI_RESULT,
  userId,
});

export const STORE_TEST_SI_RESULT = 'STORE_TEST_SI_RESULT';

export const storeTestSIResult = (result) => ({
  type: STORE_TEST_SI_RESULT,
  result,
});

export const CLEAR_TEST_SI_DATA = 'CLEAR_TEST_SI_DATA';

export const clearTestSIData = () => ({
  type: CLEAR_TEST_SI_DATA,
});
