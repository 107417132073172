import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { IconContext } from 'react-icons';
import { BiLogInCircle } from 'react-icons/bi';

import {
  Container, Row, Col, Image,
} from 'react-bootstrap';

import faviconWkCWhite from '../../assets/img/favicon-wkc-white.webp';
import socialItems from './socialItems';

import './styles.scss';

const Footer = () => {
  const thisYear = useMemo(() => new Date().getFullYear(), []);

  const loginCSS = useMemo(() => ({
    size: '1rem',
    className: 'ms-1 text-white',
  }), []);

  return (
    <footer className="footer">
      <Container>
        <Row className="py-3 text-white">
          <Col
            xs={{ span: 12, order: 2 }}
            md={{ span: 6, order: 1 }}
            className="footer__copyright"
          >
            <Image
              className="logo"
              src={faviconWkCWhite}
              fluid
              loading="lazy"
              decoding="async"
            />
            <div>
              &copy;{thisYear} Workin'Cool EURL
              <br />
              <Link
                to="/dashboard"
                className="login-link"
              >
                <small>Se connecter</small>
                <IconContext.Provider value={loginCSS}>
                  <BiLogInCircle />
                </IconContext.Provider>
              </Link>
            </div>
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            md={{ span: 6, order: 2 }}
            className="footer__social"
          >
            {
              socialItems.map(({ link, icon }) => (
                <Link
                  key={link}
                  to={link}
                  target={link.slice(0, 2) === '//' ? '_blank' : ''}
                >
                  {icon}
                </Link>
              ))
            }
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
