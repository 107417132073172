import { useEffect, useState } from 'react';
import {
  Routes, Route, Navigate, useLocation, useSearchParams, useNavigate,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import WorkinDiag from '../../views/WorkinDiag';
import TestSyndromeImposteur from '../../views/TestSyndromeImposteur';
import Dashboard from '../../containers/Dashboard';
import WorkinDiagBoard from '../../containers/WorkinDiagBoard';
import TestSyndromeImposteurBoard from '../../containers/TestSyndromeImposteurBoard';

import Report from '../../views/Reports';
import WorkinDiagReport from '../../containers/WorkinDiagReport';

const Router = ({ wakeupApiTools, wakeupApiTallyManager }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [booking, setBooking] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (pathname === '/workindiag') {
      const params = searchParams.get('booking');
      setBooking(!(params && params === 'false'));
    }
    if (pathname === '/report/workindiag' && searchParams.get('email')) {
      setEmail(searchParams.get('email'));
    } else if (pathname === '/report/workindiag' && !searchParams.get('email')) {
      navigate('*');
    }
  }, [pathname]);

  useEffect(() => {
    wakeupApiTools();
    wakeupApiTallyManager();
  }, []);

  return (
    <Routes>
      {/* Open routes */}
      <Route
        path="/workindiag"
        element={<WorkinDiag booking={booking} />}
      />
      <Route
        path="/test-syndrome-imposteur"
        element={<TestSyndromeImposteur />}
      />
      <Route
        path="/report"
        element={<Report />}
      >
        <Route
          path="workindiag"
          element={<WorkinDiagReport email={email} />}
        />
      </Route>
      {/* Protected routes */}
      <Route
        path="/dashboard"
        element={<Dashboard />}
      >
        <Route
          path="workindiag"
          element={<WorkinDiagBoard />}
        />
        <Route
          path="test-syndrome-imposteur"
          element={<TestSyndromeImposteurBoard />}
        />
      </Route>
      <Route
        path="*"
        element={<Navigate to="/workindiag" />}
      />
      {/* <Route
        path="/"
        element={<Navigate to="/workindiag" />}
      /> */}
    </Routes>
  );
};

Router.propTypes = {
  wakeupApiTools: PropTypes.func.isRequired,
  wakeupApiTallyManager: PropTypes.func.isRequired,
};

export default Router;
