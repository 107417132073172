import axios from 'axios';

export const apiTools = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://api.workincool.com/apitools/v1',
  headers: {
    'X-Client-ID': 'WKC_CLIENT_ID_9c94db97d4b0f3b386930536cfb7285a6dd1f8f0a3d62450c40e5cc29897c28d',
  },
});

export const apiTallyManager = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:3002' : 'https://api.workincool.com/tally-manager/v2',
});
