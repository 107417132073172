import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Container, Row, Col, Form, Card, Button,
} from 'react-bootstrap';
import LoadingSpin from '../../../../components/LoadingSpin';
import ChartsWorkinDiag from '../../../../components/ChartsWorkinDiag';

import './styles.scss';

const WorkinDiagBoard = ({
  users, loading, getWorkinDiagUsers, getWorkinDiagResult, userResult,
}) => {
  useEffect(() => {
    if (!loading && users.length === 0) {
      getWorkinDiagUsers();
    }
  }, [users, loading]);

  const [optionSelected, setOptionSelected] = useState(false);
  const [showWorkinDiag, setShowWorkinDiag] = useState(false);

  const handleChangeOption = () => {
    setOptionSelected(true);
    setShowWorkinDiag(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    getWorkinDiagResult(event.target.user.value);
  };
  useEffect(() => {
    if (userResult.firstname) {
      setShowWorkinDiag(true);
    }
  }, [userResult]);

  const handleBackward = () => {
    setOptionSelected(false);
    setShowWorkinDiag(false);
  };

  return (
    <div className="wd-board">
      <Container>
        <Row className="justify-content-center pt-md-3">
          <Col xs={12} md={6} className="text-center">
            <h2>Workin'Diag Dashboard</h2>
          </Col>
        </Row>
        {
          loading && <LoadingSpin color="violet" />
        }
        {
          !loading && !showWorkinDiag && users.length > 0 && (
            <Row className="justify-content-center">
              <Col xs={12} md={4}>
                <Card className="wd-board__customer-select">
                  <Form onSubmit={handleSubmit} className="">
                    <Card.Body>
                      <Form.Group controlId="user">
                        <Card.Title>
                          <Form.Label>
                            Sélectionner un client
                          </Form.Label>
                        </Card.Title>
                        <Form.Select
                          onChange={handleChangeOption}
                        >
                          {
                            !optionSelected && <option value="no option">...</option>
                          }
                          {
                            users.map(({
                              id, firstname, lastname,
                            }) => (
                              <option key={id} value={id}>
                                {firstname} {lastname}
                              </option>
                            ))
                          }
                        </Form.Select>
                      </Form.Group>
                    </Card.Body>
                    <Card.Footer className="bg-white text-center py-3">
                      <Button
                        variant="wkc-violet"
                        type="submit"
                        disabled={!optionSelected}
                      >
                        <span>Voir les résultats</span>
                      </Button>
                    </Card.Footer>
                  </Form>
                </Card>
              </Col>
            </Row>
          )
        }
        {
          !loading && showWorkinDiag && (
            <>
              <Row className="justify-content-center py-3">
                <Col xs={12} md={4} className="text-center">
                  <Card className="wd-board__chart-header">
                    <Card.Body>
                      <Card.Title as="h4" className="ff--bold">{userResult.firstname}</Card.Title>
                      <Card.Text className="">
                        <span className="text-decoration-underline">Workin'Diag réalisé le :</span>&nbsp;
                        <span className="ff--bold">{new Date(userResult.date).toLocaleDateString()}</span>
                      </Card.Text>
                      <Button
                        variant="wkc-black-outlined"
                        onClick={handleBackward}
                      >
                        <span>Retour</span>
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="justify-content-center px-0">
                <Col xs={12} className="px-0">
                  <ChartsWorkinDiag data={userResult.result} />
                </Col>
              </Row>
            </>
          )
        }
      </Container>
    </div>
  );
};

WorkinDiagBoard.propTypes = {
  users: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  getWorkinDiagUsers: PropTypes.func.isRequired,
  getWorkinDiagResult: PropTypes.func.isRequired,
  userResult: PropTypes.object.isRequired,
};

export default WorkinDiagBoard;
